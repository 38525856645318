import { MtDate } from '@mt-ng2/date-module';

export const convertToStartOfDay = (date: Date): Date => {
    const returnDate = (new MtDate(date)).toMoment().clone().startOf('day').toDate();
    const tzOffset = returnDate.getTimezoneOffset();
    returnDate.setMinutes(returnDate.getMinutes() - tzOffset);
    return returnDate;
}

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
};

export const convertToEndOfDay = (date: Date): Date => {
    const returnDate = (new MtDate(date)).toMoment().clone().add('day').endOf('day').toDate();
    const tzOffset = returnDate.getTimezoneOffset();
    returnDate.setMinutes(returnDate.getMinutes() - tzOffset);
    return returnDate;
}
